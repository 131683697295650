import API from "../utilities/LocalApiProxy";
import { OPEN_GLOBAL_SNACKBAR } from "./types";

export function fetchWhatsNewItems() {
	return function (dispatch) {
		dispatch({ type: "FETCH_WHATS_NEW_REQUEST" });
		API.get("/getWhatsNew")
			.then((results) => {
				dispatch({
					type: "FETCH_WHATS_NEW_SUCCESS",
					payload: {
						items: results.items,
						itemsHash: results.itemsHash,
					},
				});
			})
			.catch((err) => {
				console.error("error in fetching whats new items", err);
			});
	};
}

export function updateWhatsNewItems(items) {
	return function (dispatch) {
		dispatch({ type: "UPDATE_WHATS_NEW_ITEMS_REQUEST" });
		API.post("/admin/updateWhatsNew", { body: { items } })
			.then((res) => {
				// update both the items and the hash
				dispatch({
					type: "UPDATE_WHATS_NEW_ITEMS_SUCCESS",
					payload: {
						items: res.mysql.items,
						itemsHash: res.mysql.itemsHash,
					},
				});
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						variant: "success",
						message: `What's New items updated successfully.`,
					},
				});
			})
			.catch((err) => {
				console.error("error in saving whats new items", err);
				dispatch({
					type: OPEN_GLOBAL_SNACKBAR,
					payload: {
						variant: "error",
						message: `Unable to update What's New items.`,
					},
				});
			});
	};
}
